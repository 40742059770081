
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { FileInfo, ColumnsProps, AutoTableColProps } from '@/types'
import { ssscSearch, PgSsscProps } from '@/API/sssc'
import { settingSearchPdi } from '@/API/setting'
import { getSubscribedBu } from "@/API/approve"
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { baseURL } from '@/API'
import useFetch from "@/hooks/useFetch";
export default defineComponent({
    setup () {
        const store = useStore()
        const dataSoure = ref([])
        const buUnit = ref<string>()
        const modelName = ref<string>()
        const Baumust = ref<string>()
        const visible = ref<boolean>(false)
        const columns = ref<ColumnsProps[]>([])
        const buSelectData = ref<BuItem[]>([])
        const fileList = ref([])
        const buOption = ref([])
        const buSelected = ref<string>('')
        
        //查询bu options
        const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu, true, (res: any) => {
            buOption.value = res.map((item: any) => {
                return {
                    label: item.orgnmen,
                    value: item.orgid
                }
            })
        })
        
        const handleBeforeUpload = () => {
            if (!buSelected.value) {
                message.error('Please Select BU')
                return false
            }
        }

        const handleUpload = (info: FileInfo) => {
            
            console.log(11111);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // 上传文件成功
                message.success(`${info.file.name} file uploaded successfully`);
                visible.value = true
                
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            
        }
       
       
        const getColumns = (data: PgSsscProps) => {
            columns.value = []
            data.tableCol.forEach((item: AutoTableColProps, index: number) => {
                const params: ColumnsProps = {
                    title: item.display,
                    dataIndex: item.id,
                    key: item.id,
                }
                columns.value.push(params)
            })
        }
        
        const init = () => {
            buUnit.value = store.state.user.bu.orgid
            const params = {
                bu: buSelected.value
            }
            settingSearchPdi({params}).then((res: any) => {
                dataSoure.value = res.tableCell
                getColumns(res)
            })
        }
        
        // 搜索
        const getTableData = () => {
            init()
        }
        
        const headers = {
            Authorization: store.state.Authorization
        }
        // 关闭上传弹窗，需要刷新列表
        const handleClose = () => {
            visible.value = false
            fileList.value = []
            init()
        }
        onMounted(() => {
            // init()
        })
        return {
            buUnit,
            modelName,
            Baumust,
            buSelectData,
            dataSoure,
            columns,
            visible,
            fileList,
            handleClose,
            handleUpload,
            headers,
            baseURL,
            buOptions,
            buSelected,
            getTableData,
            handleBeforeUpload,
        }
    }
})
